import React, { useState, useCallback } from 'react';
import './css/index.css';
import { useSelector } from 'react-redux';
import EmailInput from './components/EmailInput';
import Popup from './components/Popup';
import { useDispatch } from 'react-redux';
function Email() {
  const dispatch = useDispatch();
  const productionPath = useSelector(
    (state) => state.config.identification.productionPath,
  );
  const layout = useSelector((state) => state.layouts.email);
  const agent = useSelector((state) => state.config.identification.agent);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [content, setContent] = useState('');
  const __sendEmail = useCallback(() => {
    var re =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    if (re.test(email)) {
      let url;
      if (productionPath === 'test') {
        url = 'http://localhost:5001/minted-62f9d/us-central1/api/send/email';
      } else {
        url =
          'https://us-central1-minted-62f9d.cloudfunctions.net/api/send/email';
      }
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Allow-Control-Access-Origin': '*',
        },
        body: JSON.stringify({
          email,
          name,
          content,
        }),
      })
        .then((res) => res.json())
        .then(({ status, msg }) => {
          console.log(msg);
          if (status) {
            dispatch({
              type: '@layouts/UPDATE_POPUP',
              payload: true,
            });
          } else {
            alert(msg);
          }
        })
        .catch((err) => {
          alert(err.code);
        });
    } else {
      alert('유효하지않은 이메일입니다.');
    }
  }, [email, productionPath, name, content, dispatch]);
  return (
    <div className="email">
      <div className="wrapper">
        <div className="email-pack">
          <div className="email-title gmarket">
            Contact <br /> through e-mail
            <div className="email-bar">
              <img src="/assets/home/line.svg" alt="" />
            </div>
          </div>
          <div className="email-input-wrapper">
            {layout.map((item, idx) => {
              return (
                <EmailInput
                  key={idx}
                  data={item}
                  agent={agent}
                  set={
                    item.type === 'name'
                      ? setName
                      : item.type === 'emails'
                      ? setEmail
                      : setContent
                  }
                />
              );
            })}
          </div>
          <div className="email-btn" onClick={__sendEmail}>
            <div className="animated"></div>
            <div className="btn-title gmarket">Send message</div>
            <div className="btn-arrow">
              <img
                src={`/assets/email/email-arrow${
                  agent === 'mobile' ? '-mb' : ''
                }.svg`}
                alt="arrow"
              />
            </div>
          </div>
        </div>
      </div>
      <Popup />
    </div>
  );
}

export default Email;
