import React from 'react';

function Card({ category, title, desc, idx, url }) {
  return (
    <a href={url} target="_blank" rel="noreferrer" className="card">
      <div className="img">
        <img
          src={`/assets/blog/blog-${idx + 1}.png`}
          srcSet={`/assets/blog/blog-${idx + 1}@2x.png 2x, /assets/blog/blog-${
            idx + 1
          }@3x.png 3x`}
          alt="blog"
        />
      </div>
      <div className="info">
        <div className="category txt-bold">{category}</div>
        <div className="title txt-bold">{title}</div>
        <div className="desc">{desc}</div>
      </div>
    </a>
  );
}

export default Card;
