import React from 'react';
import { useSelector } from 'react-redux';

import './css/index.css';
import Coin from './components/Coin';

const community = [
  {
    name: 'hopr',
    url: 'https://hoprnet.org',
    display: 'Hopr',
  },
  {
    name: 'unmarshal',
    url: 'https://unmarshal.io',
    display: 'Unmarshal',
  },
  {
    name: 'finxflo',
    url: 'https://www.finxflo.com',
    display: 'Finxflo',
  },
  {
    name: 'polkastarter',
    url: 'https://www.polkastarter.com',
    display: 'Polkastarter',
  },
  {
    name: 'bifrost-finance',
    url: 'https://bifrost.finance',
    display: 'Bifrost Finance',
  },
  {
    name: 'set-protocol',
    url: 'https://www.setprotocol.com',
    display: 'Set protocol',
  },
  {
    name: 'mint-layer',
    url: 'https://www.mintlayer.org',
    display: 'Mintlayer',
  },
  {
    name: 'shield-finance',
    url: 'https://shieldfinance.io',
    display: 'Shield Finance',
  },
  {
    name: 'blocto',
    url: 'https://blocto.portto.io/en/',
    display: 'BLOCTO',
  },
  {
    name: 'aldrin',
    url: 'https://aldrin.com/',
    display: 'Aldrin',
  },

  {
    name: 'defi11',
    url: 'https://www.defieleven.com',
    display: 'DEFI11',
  },
  {
    name: 'dfyn',
    url: 'https://dfyn.network/',
    display: 'Dfyn',
  },
  {
    name: 'locgame',
    url: 'https://locgame.io',
    display: 'LOCGame',
  },
  {
    name: 'mochi',
    url: 'https://mochiswap.io',
    display: 'Mochi',
  },
  {
    name: 'roseon',
    url: 'https://www.roseon.finance',
    display: 'Roseon',
  },
  {
    name: 'solanium',
    url: 'https://www.solanium.io',
    display: 'Solanium',
  },
  {
    name: 'standard-protocol',
    url: 'https://standard.tech',
    display: 'Standard Protocol',
  },
  {
    name: 'coinburp',
    url: 'https://www.coinburp.com/',
    display: 'CoinBurp',
  },
  {
    name: 'odds',
    url: 'https://oddz.fi',
    display: 'Oddz',
  },
  {
    name: 'cryptoverse',
    url: 'https://cryptomeda.tech',
    display: 'Cryptomeda',
  },
  {
    name: 'polkafantasy',
    url: 'https://polkafantasy.com/',
    display: 'Polkafantasy',
  },
  {
    name: 'only1',
    url: 'https://only1.io/',
    display: 'Only1',
  },
  {
    name: 'plutos',
    url: 'https://www.plutos.network/',
    display: 'Plutos Network',
  },
  {
    name: 'cropper',
    url: 'https://cropper.finance/',
    display: 'Cropper Finance',
  },
  {
    name: 'parrot',
    url: 'https://parrot.fi/',
    display: 'Parrot',
  },
  {
    name: 'cyclos',
    url: 'https://cyclos.io/',
    display: 'cyclos',
  },
  {
    name: 'port',
    url: 'https://port.finance/',
    display: 'Port Finance',
  },
  {
    name: 'ratio',
    url: 'https://www.ratio.finance/',
    display: 'Ratio Finance',
  },
];

const investments = [
  {
    name: 'fusible',
    url: 'https://fusible.io',
    display: 'Fusible',
  },
  {
    name: 'hopr',
    url: 'https://hoprnet.org',
    display: 'Hopr',
  },
  {
    name: 'theapis',
    url: 'https://www.theapis.io',
    display: 'TheAPIS',
  },
  {
    name: 'torus',
    url: 'https://tor.us',
    display: 'Torus',
  },
  {
    name: 'nft-alley',
    url: 'https://nftalley.io',
    display: 'NFT Alley',
  },
  {
    name: 'unore',
    url: 'https://unore.io',
    display: 'Unore',
  },
  {
    name: 'human-protocol',
    url: 'https://www.humanprotocol.org',
    display: 'Human Protocol',
  },
  {
    name: 'unmarshal',
    url: 'https://unmarshal.io',
    display: 'Unmarshal',
  },
  {
    name: 'standard-protocol',
    url: 'https://standard.tech',
    display: 'Standard Protocol',
  },
  {
    name: 'shield-finance',
    url: 'https://shieldfinance.io',
    display: 'Shield Finance',
  },
  {
    name: 'mint-layer',
    url: 'https://www.mintlayer.org',
    display: 'Mintlayer',
  },
  {
    name: 'mochi',
    url: 'https://mochiswap.io',
    display: 'Mochi',
  },
  {
    name: 'relite',
    url: 'https://relite.finance',
    display: 'Relite',
  },
  {
    name: 'uniqly',
    url: 'https://www.uniqly.io',
    display: 'Uniqly',
  },
  {
    name: 'locgame',
    url: 'https://locgame.io',
    display: 'LOCGame',
  },
  {
    name: 'solanium',
    url: 'https://www.solanium.io',
    display: 'Solanium',
  },
  {
    name: 'cryptoverse',
    url: 'https://cryptomeda.tech',
    display: 'Cryptomeda',
  },
  {
    name: 'klaytn',
    url: 'https://www.klaytn.com',
    display: 'Klaytn',
  },
  {
    name: '2crazy',
    url: 'https://www.2crazy.com/en',
    display: '2crazy',
  },
  {
    name: 'defi11',
    url: 'https://www.defieleven.com',
    display: 'DEFI11',
  },
  {
    name: 'pandora',
    url: 'https://pandora.finance',
    display: 'Pandora',
  },
  {
    name: 'melalie',
    url: 'https://www.melalie.com',
    display: 'Melalie',
  },
  {
    name: 'aldrin',
    url: 'https://aldrin.com/',
    display: 'Aldrin',
  },

  {
    name: 'coinburp',
    url: 'https://www.coinburp.com/',
    display: 'CoinBurp',
  },
  {
    name: 'sigmadex',
    url: 'https://sigmadex.org',
    display: 'SIGMADEX',
  },
  {
    name: 'propel',
    url: 'https://www.propel.xyz/',
    display: 'Propel',
  },
  {
    name: 'roseon',
    url: 'https://www.roseon.finance',
    display: 'Roseon',
  },
  {
    name: 'cryptopunt',
    url: 'https://greenheartcbd.ie/',
    display: 'CRYPTOPUNT',
  },
  {
    name: 'cere',
    url: 'https://www.cere.network/',
    display: 'Cere',
  },
  {
    name: 'bumper',
    url: 'https://landing.bumper.fi/',
    display: 'Bumper',
  },
  {
    name: 'vaulty',
    url: 'https://vaulty.fi/',
    display: 'Vaulty',
  },
  {
    name: 'scale-swap',
    url: 'https://scaleswap.io',
    display: 'scaleswap',
  },
  {
    name: 'synapse',
    url: 'https://synapse.network/',
    display: 'Synapse',
  },
  {
    name: 'orojectseed',
    url: 'https://projectseed.io/',
    display: 'ProjectSeed',
  },
  {
    name: 'only1',
    url: 'https://only1.io/',
    display: 'Only1',
  },
  {
    name: 'lithium',
    url: 'https://lith.finance/',
    display: 'Lithium',
  },
  {
    name: 'casper',
    url: 'https://casperlabs.io/',
    display: 'Casper',
  },
  {
    name: 'xp',
    url: 'https://xp.network/',
    display: 'XP',
  },
  {
    name: 'buni',
    url: 'https://buni.finance/',
    display: 'Buni',
  },
  {
    name: 'parrot',
    url: 'https://parrot.fi/',
    display: 'Parrot',
  },
  {
    name: 'matrixswap',
    url: 'https://synapse.network/',
    display: 'MatrixSwap',
  },
  {
    name: 'panther',
    url: 'https://www.pantherprotocol.io/',
    display: 'Panther',
  },
  {
    name: 'vegaswap',
    url: 'https://vegaswap.io/',
    display: 'vegaswap',
  },
  {
    name: 'polkafantasy',
    url: 'https://polkafantasy.com/',
    display: 'Polkafantasy',
  },
  {
    name: 'plutos',
    url: 'https://www.plutos.network/',
    display: 'Plutos Network',
  },
  {
    name: 'cropper',
    url: 'https://cropper.finance/',
    display: 'Cropper Finance',
  },
  {
    name: 'cyclos',
    url: 'https://cyclos.io/',
    display: 'cyclos',
  },
];

function Portfolio() {
  const lang = useSelector((state) => state.config.identification.language);
  const agent = useSelector((state) => state.config.identification.agent);
  return (
    <div className="portfolio container">
      <div className="wrapper">
        <div className="top">
          <div className="title txt-bold gmarket">Minted lab portfolio</div>
          {lang === 'KR' ? (
            <div className="desc">
              Minted lab은 국내외의 다양한 블록체인 프로젝트와 함께 성장하고
              있습니다
            </div>
          ) : agent !== 'mobile' ? (
            <div className="desc">
              Minted Lab is growing with various blockchain <br /> projects at
              home and abroad.
            </div>
          ) : (
            <div className="desc">
              Minted Lab is growing with <br /> various blockchain projects at{' '}
              <br /> home and abroad.
            </div>
          )}
        </div>
        <div className="token-investment coin-list">
          <div className="title-set">
            <div className="title gmarket txt-bold">Token Investments</div>
            <div className="underline">
              <img src="/assets/common/line.svg" alt="line" />
            </div>
          </div>
          <ul className="contents">
            {investments.map((item, idx) => {
              return <Coin {...item} key={idx} />;
            })}
          </ul>
        </div>
        <div className="community-building coin-list">
          <div className="title-set">
            <div className="title gmarket txt-bold">Community Building</div>
            <div className="underline">
              <img src="/assets/common/line.svg" alt="line" />
            </div>
          </div>
          <ul className="contents">
            {community.map((item, idx) => {
              return <Coin {...item} key={idx} />;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
