import React from 'react';
import './css/index.css';
import { useSelector } from 'react-redux';
import ContactBox from './components/ContactBox';
function About() {
  const layout = useSelector((state) => state.layouts.about);
  const agent = useSelector((state) => state.config.identification.agent);
  const language = useSelector((state) => state.config.identification.language);
  return (
    <div className={`about container ${language !== 'KR' && 'gmarket'}`}>
      <div className="wrapper">
        <div className="section1 ">
          <div className="about-title gmarket ">
            {agent !== 'mobile' ? (
              <span>
                Minting <br /> value of project with <br /> community
              </span>
            ) : (
              <span>
                Minting <br /> value of
                <br /> project with <br />
                community
              </span>
            )}
            <div className="about-bar">
              <img src="/assets/home/line.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="section2">
        <div className="wrapper">
          <div className="section2-wrapper">
            <div className="left gmarket txt-bold">
              about <br /> minted lab
              <div className="section2-bar">
                <img src="/assets/home/line.svg" alt="" />
              </div>
            </div>
            <div className="right">
              {layout.section2.map((data, idx) => {
                return (
                  <ContactBox
                    key={idx}
                    data={data}
                    language={language}
                    agent={agent}
                    idx={idx}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div
        className="section3"
        style={
          agent === 'mobile' && language === 'ENG'
            ? {
                height: '1300px',
              }
            : undefined
        }
      >
        <div className="wrapper">
          <div className="section3-pack">
            <div className="section3-left">
              <div className="section3-title-pack gmarket">
                <div className="section3-title">
                  Minted lab <br /> strategy
                </div>
                <div className="section3-bar">
                  <img src="/assets/about/about-line.svg" alt="" />
                </div>
              </div>
              {language === 'KR' ? (
                <div className="section3-content">
                  Minted Lab은 암호화폐 시장에서 프로젝트와 투자자 간의 정보
                  불균형을 해결하고, 투자자 들에 전문적이고 정확한 정보를
                  제공하기 위해 2020년도 3월에 설립되었습니다. <br />
                  <br /> Minted Lab은 다양한 블록체인 프로젝트들을 객관적인
                  시각으로 점검하고, 분석하여 투자 자들에게 더욱 쉽고 도움이
                  되는 정보를 제공합니다. 이를 통해 어려운 암호화폐 투자에 대한
                  일반 대중들의 접근성을 높이는 것이 커뮤니티의 목표입니다.{' '}
                  <br />
                  <br /> Minted Lab은 쉼없이 변화하는 암호화폐 시장의 흐름을
                  놓치지 않고, 다양한 정보들을 분석 합니다. 선별된 정보는
                  투자자들로 하여금 올바른 방향의 투자를 진행해 나갈 수 있도록
                  돕습 니다. <br />
                  <br />
                  <span className="txt-bold">
                    Minted Lab은 국내를 넘어 전 세계의 다양한 블록체인
                    프로젝트들을 분석하고, 정보를 제공하는 Global-Korea 서포터즈
                    역할을 합니다. 이는 글로벌 블록체인 프로젝트에 대한 국내
                    투자자들의 시야를 넓히고, 투자 접근성을 높이는데 기여합니다.
                  </span>
                </div>
              ) : (
                <div
                  className="section3-content"
                  style={{ width: agent === 'desktop' ? '539px' : undefined }}
                >
                  Minted Lab was established in March 2020 to resolve the
                  information imbalance between projects and investors in the
                  cryptocurrency market and to provide professional and accurate
                  information to investors. <br />
                  <br /> Minted Lab provides easier and more helpful information
                  to investors by objectively inspecting and analyzing various
                  blockchain projects. Through this, the goal of the community
                  is to increase the accessibility of the general public to
                  difficult cryptocurrency investments. <br /> <br /> Minted Lab
                  analyzes various information without missing the constantly
                  changing trend of the cryptocurrency market. Selected
                  information helps investors make investments in the right
                  direction. <br /> <br />
                  <span className="txt-bold">
                    Minted Lab acts as a Global-Korea supporter who analyzes and
                    provides information on various blockchain projects around
                    the world beyond Korea. This contributes to broadening the
                    view of Korean investors for global blockchain projects and
                    increasing accessibility to investment.
                  </span>
                </div>
              )}
            </div>
            <div className="section3-right">
              <img src="/assets/about/section3.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
