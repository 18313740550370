import React, { useCallback, useRef, useEffect } from 'react';
import './css/index.css';
import { useSelector, useDispatch } from 'react-redux';
import { __UPDATE_AGENT__ } from './actiontypes';
import { useHistory, useLocation } from 'react-router-dom';
function Header() {
  const ObRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const layout = useSelector((state) => state.layouts.header.menu);
  const lagnuagestate = useSelector(
    (state) => state.config.identification.language,
  );
  const isOpen = useSelector((state) => state.layouts.isOpen);
  const agent = useSelector((state) => state.config.identification.agent);
  const __changeLanguage = useCallback(() => {
    dispatch({
      type: '@config/CHANGE_LANGUANGE',
      payload: lagnuagestate === 'KR' ? 'ENG' : 'KR',
    });
  }, [dispatch, lagnuagestate]);
  const __navMenu = useCallback(() => {
    if (isOpen) {
      dispatch({
        type: '@layouts/MENU_CHANGE',
        payload: false,
      });
    } else {
      dispatch({
        type: '@layouts/MENU_CHANGE',
        payload: true,
      });
    }
  }, [isOpen, dispatch]);
  const __navHistory = useCallback(
    (nav) => {
      history.push(nav);
    },
    [history],
  );
  const __navHome = useCallback(() => {
    history.push('/');
  }, [history]);
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, [location]);
  useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      const node = entries[0].target;
      const browserWidth = node.offsetWidth;
      if (browserWidth <= 767) {
        dispatch({
          type: __UPDATE_AGENT__,
          payload: 'mobile',
        });
      } else if (browserWidth <= 1023) {
        dispatch({
          type: __UPDATE_AGENT__,
          payload: 'tablet',
        });
      } else {
        dispatch({
          type: __UPDATE_AGENT__,
          payload: 'desktop',
        });
      }
    });
    ro.observe(ObRef.current);
    return ro.disconnect;
  }, [ObRef, dispatch]);

  return (
    <div className="header" ref={ObRef}>
      <div className="logo" onClick={__navHome}>
        <img src="/assets/header/logo.svg" alt="로고" />
      </div>
      <div className="right">
        {agent === 'desktop' ? (
          <>
            <div className="header-menu">
              {layout.map(({ title, nav }, idx) => {
                return (
                  <div
                    key={idx}
                    className="menu"
                    onClick={() => {
                      __navHistory(nav);
                    }}
                  >
                    {title}
                    {location.pathname === nav ? (
                      <div className="bar" />
                    ) : undefined}
                  </div>
                );
              })}
            </div>
            <div className="language" onClick={__changeLanguage}>
              {lagnuagestate}
            </div>
          </>
        ) : (
          <>
            {agent !== 'desktop' ? (
              <div className="mb-la" onClick={__changeLanguage}>
                {lagnuagestate}
              </div>
            ) : undefined}
            <div className="menu-img" onClick={__navMenu}>
              {isOpen ? (
                <img src="/assets/header/cancel.svg" alt="cancel" />
              ) : (
                <img src="/assets/header/menu.svg" alt="menu" />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Header;
