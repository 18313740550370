import React from 'react';
import { useSelector } from 'react-redux';
import './css/index.css';
import { Link } from 'react-router-dom';
import useScrollFadeIn from '../About/hook/useScrollFadeIn';

function Home() {
  const agent = useSelector((state) => state.config.identification.agent);
  const lang = useSelector((state) => state.config.identification.language);

  return (
    <div className="home container">
      <div className="wrapper">
        <div className="section">
          <div className="left">
            <div className="slogan gmarket txt-bold">
              Minting <br />
              value of <br />
              project with <br />
              community <br />
            </div>
            <div className="underline">
              <img src="/assets/home/line.svg" alt="라인" />
            </div>
            <Link to="/about" className="about-go">
              <div className="animated"></div>
              <div className="title gmarket">About Minted Lab</div>
              <div className="asset">
                <img src="/assets/home/next.svg" alt="다음" />
              </div>
            </Link>
          </div>
          <div className="right">
            <div className="illust">
              <img src="/assets/home/illust.svg" alt="일러스트" />
            </div>
          </div>
        </div>

        <div className="introduce">
          <div className="card" {...useScrollFadeIn(0)}>
            <div className="asset">
              <img src="/assets/home/icon-1.svg" alt="icon" />
            </div>
            <div className="slogan consulting">
              <div className="title txt-bold gmarket">Consulting</div>
              <div className="underline">
                <img src="/assets/home/line.svg" alt="라인" />
              </div>
            </div>
            {lang === 'KR' ? (
              <div className="desc">
                Minted Lab만의 전문적이고 정확한 분석을 통해 암호화폐 시장의
                수많은 프로젝트를 마케팅 합니다
              </div>
            ) : (
              <div className="desc gmarket">
                Marketing numerous projects in the cryptocurrency market through
                professional and accurate analysis of Minted Lab.
              </div>
            )}
          </div>
          {agent !== 'mobile' ? <div className="vr" /> : <div className="hr" />}
          <div className="card" {...useScrollFadeIn(0.05)}>
            <div className="asset">
              <img src="/assets/home/icon-2.svg" alt="icon" />
            </div>
            <div className="slogan marketing">
              <div className="title txt-bold gmarket">Marketing</div>
              <div className="underline">
                <img src="/assets/home/line.svg" alt="라인" />
              </div>
            </div>
            {lang === 'KR' ? (
              <div className="desc">
                빠르게 변화하는 암호화폐 시장의 흐름에 맞추어 다양하고 정확한
                정보를 투자자에게 제공합니다.
              </div>
            ) : (
              <div className="desc gmarket">
                In order to close the information gap in accordance with the
                rapidly changing cryptocurrency market trend, we provide various
                and accurate information to investors.
              </div>
            )}
          </div>
          {agent !== 'mobile' ? <div className="vr" /> : <div className="hr" />}
          <div className="card" {...useScrollFadeIn(0.1)}>
            <div className="asset">
              <img src="/assets/home/icon-3.svg" alt="icon" />
            </div>
            <div className="slogan building">
              <div className="title txt-bold gmarket">Building</div>
              <div className="underline">
                <img src="/assets/home/line.svg" alt="라인" />
              </div>
            </div>
            {lang === 'KR' ? (
              <div className="desc">
                전세계의 블록체인 프로젝트 정보를 제공함으로써 올바르게 투자할
                수 있게 합니다.
              </div>
            ) : (
              <div className="desc gmarket">
                By providing information on blockchain projects around the
                world, we can help you invest correctly.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
