import React from 'react';
import './css/index.css';
import { useLocation } from 'react-router-dom';

function Footer() {
  const location = useLocation();

  return (
    <div
      className="footer"
      style={location.pathname === '/email' ? { display: 'none' } : undefined}
    >
      <div className="wrapper">
        <div className="left">
          <div className="logo">
            <img src="/assets/footer/logo.svg" alt="로고" />
          </div>
          <div className="desc">
            reechew@minted-lab.com <br /> ©Mintedlab. All rights reserved.
          </div>
        </div>
        <div className="right">
          <ul className="out-link">
            <li className="el">
              <a
                href="https://t.me/minted_labs"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/footer/telegram.svg" alt="텔레그렘" />
              </a>
            </li>
            <li className="el">
              <a
                href="https://open.kakao.com/o/gFVbSeSd"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/footer/kakao.svg" alt="카카오톡" />
              </a>
            </li>
            <li className="el">
              <a
                href="https://twitter.com/minted_lab"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/footer/twitter.svg" alt="트위터" />
              </a>
            </li>
          </ul>
          <div className="copyright">©Mintedlab. All rights reserved.</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
