import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

function Popup() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isPopup = useSelector((state) => state.layouts.emailPopup);
  const language = useSelector((state) => state.config.identification.language);
  const [isOn, setIsOn] = useState(false);
  const __navHome = useCallback(() => {
    setIsOn(false);
    setTimeout(() => {
      dispatch({
        type: '@layouts/UPDATE_POPUP',
        payload: false,
      });
      history.push('/');
    }, 100);
  }, [dispatch, history]);
  const __cancel = useCallback(() => {
    setIsOn(false);
    setTimeout(() => {
      dispatch({
        type: '@layouts/UPDATE_POPUP',
        payload: false,
      });
    }, 100);
  }, [dispatch]);
  useEffect(() => {
    if (isPopup) {
      setTimeout(() => {
        setIsOn(true);
      }, 100);
    }
    return () => {};
  }, [isPopup]);
  return (
    <div
      className={`popup `}
      style={!isPopup ? { display: 'none' } : undefined}
    >
      <div className="popup-back" />
      <div className={`popup-box ${!isOn ? 'non' : ''}`}>
        <div className="popup-cancel" onClick={__cancel}>
          <img src="/assets/email/email-cancel.svg" alt="cancel" />
        </div>
        <div className="popup-box-wrapper">
          <div className="popup-title gmarket">
            <div className="pop-title"> Thank you</div>
            <div className="popup-bar">
              <img src="/assets/home/line.svg" alt="" />
            </div>
          </div>
          {language === 'ENG' ? (
            <div className="popup-sub">
              감사합니다. 문의가 접수되었습니다. <br /> 일반적으로 며칠 내에
              응답을 받으실 수 있지만, <br /> 경우에 따라 오래 걸릴 수 있습니다.
            </div>
          ) : (
            <div className="popup-sub">
              Thank you. Your inquiry has been received. <br /> Usually you can
              get a response within a few days, <br /> This can take a long time
              in some cases.
            </div>
          )}
          <div className="popup-btn" onClick={__navHome}>
            <div className="animated"></div>
            <div className="popup-btn-title gmarket">Go mainpage</div>
            <div className="popup-btn-arrow">
              <img src="/assets/email/email-arrow.svg" alt="arrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popup;
