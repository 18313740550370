import React from 'react';
import Card from './components/Card';
import './css/index.css';
import { useSelector } from 'react-redux';

const data = [
  {
    category: 'Polkastarter',
    title: '[Polkastarter 폴카스타터]\n폴카스타터 IDO 참여 방법',
    desc:
      '최근 다양한 프로젝트들이 IDO로 세일 뒤 크게 상승하면서 폴카스타터 커뮤니티에 새로 참여해주시는 분들이 많으신데 IDO 참여방법을 모르실 경우 해당 포스팅을 참고해주시고 추가적인 질문사항이 있으신 분들은 아래 링크를 통해 커뮤니티에서 질문해주시면 감사하겠습니다.',
    url: 'https://mychew8314.tistory.com/269',
  },
  {
    category: '클레이튼(Klaytn)관련',
    title: '[분석하쮸] 클레이튼 VS 링크 카카오와 네이버의 양상 비교',
    desc:
      '국내 대표 IT 기업인 카카오와 네이버. 대표적인 국내 테크핀 경쟁 회사로 암호화폐 시장에서도 둘의 경쟁은 심화될 것으로 보입니다. 카카오의 경우 블록체인 기술 계열사 그라운드X가 만든 클레이튼과 네이버 자회사 라인이 만든 링크체인이 그 주인공입니다.',
    url: 'https://mychew8314.tistory.com/107?category=766097',
  },
  {
    category: '분석하쮸',
    title: '데이터 개인 정보 보호 플랫폼 - HOPR',
    desc:
      'HOPR 프로토콜은 스위스 취리히에 본사를 두고 있는 프로젝트로 사용자가 관리하는 분산형 P2P 네트워크인 HOPR 네트워크는 HOPR를 독립적으로 유지하기 때문에 인터넷 위에 메타 데이터 감시를 불가능하게 하는 대체 인프라를 제공합니다.',
    url: 'https://mychew8314.tistory.com/278?category=739443',
  },
  {
    category: '분석하쮸',
    title: '블록체인 기반 슈퍼컴퓨터 - 디피니티 (DFINITY)',
    desc:
      '오늘 포스팅을 통해 소개해드릴 프로젝트는 블록체인 기반 슈퍼컴퓨터 - 디피니티 (DFINITY) 입니다. 디피니티 프로젝트는 최근 새롭게 시작하는 많은 프로젝트들과 달리 2015년 1월에 고안된 아이디어로써 코인 시장의 전성기라고 할 수 있는 17 ~ 18년도에 폴카닷, 코스모스 등과 어깨를 나란히 하는 유망한 프로젝트로 손꼽혔습니다.',
    url: 'https://mychew8314.tistory.com/263#comment8390102',
  },
  {
    category: '셋 프로토콜 (Set Protocol)',
    title: '암호화폐 지수의 생성 및 채택 - Index Cooperative',
    desc:
      'Index Cooperative는 암호화폐 지수의 생성 및 채택에 목표를 둔 최초의 커뮤니티 기반 분산형 조직으로 세계의 많은 금융 전문가, 암호화폐 전문가 및 브랜드와 협력하여 ',
    url: 'https://mychew8314.tistory.com/206?category=829563',
  },
  {
    category: '바이프로스트 파이낸스 (Bifrost Finance)',
    title: '인기가 높아지고 있는 스테이킹 파생상품 시장',
    desc:
      '스테이킹 파생상품들은 최근 많은 관심을 받고 있습니다. Bifrost, StaFi, Acala 등 인기 있는 프로젝트를 살펴보겠습니다. Ethereum 2.0 및 Polkadot 기반으로 한 스테이킹 파생 상품 프로젝트들이 하나둘씩 등장하기 시작했습니다.',
    url: 'https://mychew8314.tistory.com/223?category=823447',
  },
];

function Blog() {
  const language = useSelector((state) => state.config.identification.language);
  const agent = useSelector((state) => state.config.identification.agent);
  return (
    <div className="blog container">
      <div className="wrapper">
        <div className="top">
          <div className="left">
            <div className="title txt-bold gmarket">Minted Lab Blog</div>
            {language === 'KR' ? (
              <div className="subtitle">
                Minted Lab의 전문적이고 정확한 프로젝트분석글을 만날 수 있는
                블로그입니다.
              </div>
            ) : agent !== 'mobile' ? (
              <div className="subtitle gmarket">
                You can find professional and accurate project <br />
                analysis articles on Minted Lab’s Blog.
              </div>
            ) : (
              <div className="subtitle gmarket">
                You can find professional and
                <br /> accurate project analysis
                <br /> articles on Minted Lab’s Blog.
              </div>
            )}
          </div>
          <div className="right">
            <a
              href="https://mychew8314.tistory.com/"
              target="_blank"
              rel="noreferrer"
              className="go-blog"
            >
              <div className="animated"></div>
              <div className="title gmarket">Minted Lab blog</div>
              <div className="asset">
                <img src="/assets/blog/next.svg" alt="next" />
              </div>
            </a>
          </div>
        </div>
        <div className="contents">
          {data.map((item, idx) => {
            return <Card key={idx} idx={idx} {...item} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default Blog;
