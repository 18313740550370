const initialState = {
  isOpen: false,
  about: {
    section2: [
      {
        img: 'section2-1',
        title: '전문적인 분석',
        engtitle: 'Professional analysis',
        engsub:
          'Marketing numerous projects in the cryptocurrency market through professional and accurate analysis of Minted Lab.',
        sub: 'Minted Lab만의 전문적이고 정확한 분석을 통해 암호화폐 시장의 수많은 프로젝트를 마케팅 합니다',
        tag: 'Consulting',
        delay: 0.2,
        mbdelay: 0,
      },
      {
        img: 'section2-2',
        title: '종합 정보 채널',
        sub: '빠르게 변화하는 암호화폐 시장의 흐름에 맞추어 정보의 격차를 줄이며, 다양하고 정확한 정보를 투자자에게 제공합니다.',
        tag: 'Marketing',
        engtitle: `Comprehensive 
information channel`,
        engsub:
          'In order to close the information gap in accordance with the rapidly changing cryptocurrency market trend, we provide various and accurate information to investors.',
        delay: 0.4,
        mbdelay: 0,
      },
      {
        img: 'section2-3',
        title: '글로벌 서포터즈',
        sub: 'Minted Lab은 전세계의 다양한 블록체인 프로젝트가 최고의 성적을 낼 수 있도록 서포터즈 역할을 하며, 프로젝트의 정보를 제공함으로써 국내 투자자들이 올바르게 투자할 수 있게 합니다.',
        tag: 'Building',
        tb: '프로젝트와 투자자들의 브릿지',
        engtb: 'Bridge between projects and investors',
        engtitle: 'Global Supporters ',
        engsub:
          'Minted Lab acts as a supporter for various blockchain projects around the world to achieve the best performance and provides information about the project so that Korean investors can invest to them.',
        delay: 0.6,
        mbdelay: 0,
      },
    ],
  },
  menu: [
    {
      title: 'About',
      nav: '/about',
    },
    {
      title: 'Portfolio',
      nav: '/portfolio',
    },
    {
      title: 'Blog',
      nav: '/blog',
    },
    {
      title: 'Community',
      nav: '/community',
    },
  ],
  header: {
    menu: [
      {
        title: 'About',
        nav: '/about',
      },
      {
        title: 'Portfolio',
        nav: '/portfolio',
      },
      {
        title: 'Blog',
        nav: '/blog',
      },
      {
        title: 'Community',
        nav: '/community',
      },
    ],
  },
  community: {
    contact: [
      {
        img: 'telegram',
        title: 'Telegram',
        content: `빠르고 정확한 소식을
얻을 수 있는 Minted Lab의
Telegram 커뮤니티 입니다. `,
        eng: `Minted Lab’s Telegram
community where you can
get fast and accurate news.`,
        link: 'https://t.me/minted_labs',
        type: 'te',
      },
      {
        img: 'kakao',
        title: 'Kakao Talk',
        content: `다양한 투자자들이
서로 소통하는 Minted Lab의
Kakao Talk 커뮤니티 입니다.`,
        eng: `Minted Lab’s Kakao Talk
community where various
investors communicate
with each other.`,
        link: 'https://open.kakao.com/o/gFVbSeSd',
        type: 'ka',
      },
      {
        img: 'twitter',
        title: 'Twitter',
        content: `시장 동향을 빠르게
파악할 수 있는
Minted Lab의 Twitter 입니다.`,
        eng: `Minted Lab’s Twitter
where you can quickly grasp
market trends.`,
        link: ' https://twitter.com/MintedLab',
        type: 'tw',
      },
      {
        img: 'blog',
        title: 'Blog',
        content: `빠르고 정확한 소식을
얻을 수 있는 Minted Lab의
블로그 입니다.`,
        eng: `Minted Lab’s Blog
community where you can 
get fast and accurate 
news.`,
        link: 'https://mychew8314.tistory.com/',
        type: 'bl',
      },
    ],
    question: [
      {
        img: 'mail',
        title: '이메일 문의',
        sub: 'Contact through E-mail',
        link: '/email',
        engtitle: 'through E-mail',
      },
      {
        img: 'message',
        title: '텔레그램 문의',
        sub: 'Contact through Telegram',
        link: 'https://t.me/minted_labs',
        engtitle: 'through Telegram',
      },
    ],
  },
  email: [
    {
      title: 'Name',
      type: 'name',

      style: {
        width: '203px',
        marginRight: '11px',
        height: '43px',
      },
      mb: {
        width: '100%',
        height: '43px',
      },
    },
    {
      title: 'Email',
      type: 'emails',

      style: {
        width: '386px',
        height: '43px',
      },
      mb: {
        width: '100%',
        height: '43px',
      },
    },
    {
      title: 'Contents',
      type: 'content',

      style: {
        width: '600px',
        height: '132px',
      },
      mb: {
        width: '100%',
        height: '224px',
      },
    },
  ],
  emailPopup: false,
};
const layouts = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@layouts/UPDATE_POPUP':
      return {
        ...state,
        emailPopup: payload,
      };
    case '@layouts/MENU_CHANGE':
      return {
        ...state,
        isOpen: payload,
      };
    default:
      return state;
  }
};
export default layouts;
