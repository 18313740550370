import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './css/index.css';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
function Menu() {
  const loaction = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const layout = useSelector((state) => state.layouts.menu);
  const isOpen = useSelector((state) => state.layouts.isOpen);
  const lagnuagestate = useSelector(
    (state) => state.config.identification.language,
  );
  const __navHistory = useCallback(
    (nav) => {
      history.push(nav);
    },
    [history],
  );
  useEffect(() => {
    dispatch({
      type: '@layouts/MENU_CHANGE',
      payload: false,
    });
    return () => {};
  }, [loaction, dispatch, lagnuagestate]);
  return (
    <div className={`mbmenu ${!isOpen ? 'exit' : ''} gmarket`}>
      <div className="mbmenu-wrapper">
        {layout.map(({ title, nav }, idx) => {
          return (
            <div
              className="menu"
              onClick={() => {
                __navHistory(nav);
              }}
              key={idx}
            >
              <div className="menu-title">{title}</div>
              <div className="menu-bar" />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Menu;
