import React from 'react';

function EmailInput({ data: { title, type, style, mb }, agent, set }) {
  return (
    <div className={`input-pack ${type}`}>
      <div className="input-title gmarket">{title}</div>
      {type !== 'content' ? (
        <input
          type="text"
          className={`input-text`}
          style={agent === 'mobile' ? mb : style}
          onBlur={(e) => {
            set(e.target.value);
          }}
        />
      ) : (
        <textarea
          className="input-text"
          style={agent === 'mobile' ? mb : style}
          onBlur={(e) => {
            set(e.target.value);
          }}
        />
      )}
    </div>
  );
}

export default EmailInput;
