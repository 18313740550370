import React, { useCallback } from 'react';
import './css/index.css';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
function Community() {
  const history = useHistory();
  const layout = useSelector((state) => state.layouts.community);
  const agent = useSelector((state) => state.config.identification.agent);
  const launage = useSelector((state) => state.config.identification.language);
  const __navMail = useCallback(() => {
    history.push('/email');
  }, [history]);
  return (
    <div className={`community ${launage === 'ENG' ? 'gmarket' : ''}`}>
      <div className="wrapper">
        <div className="cm-top">
          <div className="cm-title">
            Minted Lab
            <br /> Community
          </div>
          <div className="cm-sub">
            {launage === 'KR'
              ? 'Minted lab Community를 통해 투자자들에게 더욱 쉽고 도움이 되는 정보를 제공합니다.'
              : `We provide easier and more helpful information to
investors through the Minted Lab Community.`}
          </div>
        </div>
        <div
          className="cm-middle"
          style={
            agent === 'desktop' && launage === 'ENG'
              ? {
                  marginTop: '90px',
                }
              : undefined
          }
        >
          {layout.contact.map(
            ({ img, title, content, link, type, eng }, idx) => {
              return (
                <React.Fragment key={idx}>
                  {agent === 'desktop' ? (
                    img !== 'telegram' ? (
                      <div className="cm-bar" />
                    ) : undefined
                  ) : undefined}
                  <div
                    className={`contact-box ${img}`}
                    style={
                      agent === 'desktop'
                        ? {
                            height: launage === 'ENG' ? '250px' : '240px',
                          }
                        : agent === 'tablet'
                        ? {
                            height:
                              type === 'ka' || type === 'te'
                                ? '240px'
                                : '270px',
                          }
                        : agent === 'mobile' && type === 'bl'
                        ? {
                            borderBottom: 0,
                          }
                        : undefined
                    }
                  >
                    <div className="contact-img">
                      <img src={`/assets/community/${img}.svg`} alt="icon" />
                    </div>
                    <div className="contact-title">{title}</div>
                    <div
                      className={`contact-content ${type}`}
                      style={
                        launage === 'ENG'
                          ? type === 'ka'
                            ? {
                                width: agent === 'tablet' ? '313px' : '100%',
                                fontSize: 12,
                              }
                            : type === 'tw'
                            ? {
                                width:
                                  agent === 'desktop'
                                    ? '189px'
                                    : agent === 'tablet'
                                    ? '300px'
                                    : '270px',
                                fontSize: 12,
                              }
                            : { fontSize: 12 }
                          : undefined
                      }
                    >
                      {launage === 'KR' ? content : eng}
                    </div>
                    <a className="contact-btn" href={link} target="__blank">
                      <div className="animated"></div>
                      <div className="title">{title}</div>
                      <div className="contact-arrow">
                        <img
                          src={`/assets/community/short-arrow${
                            agent === 'tablet' ? '-tablet' : ''
                          }.svg`}
                          alt="arrow"
                        />
                      </div>
                    </a>
                  </div>
                </React.Fragment>
              );
            },
          )}
        </div>
        <div className="cm-bottom">
          <div className="bottom-title">
            Contact Minted Lab
            <div className="bottom-line">
              <img src="/assets/common/contact-line.svg" alt="" />
            </div>
          </div>
          <div
            className="bottom-sub"
            style={
              launage === 'ENG'
                ? {
                    marginTop: '18px',
                    width: agent === 'mobile' ? '198px' : undefined,
                  }
                : undefined
            }
          >
            {launage === 'KR'
              ? '  Minted lab에 대해 궁금한 점이 있으신가요?'
              : 'Do you have any questions about Minted Lab?'}
          </div>
          <div className="bottom-list">
            {layout.question.map(({ img, title, sub, link, engtitle }, idx) => {
              if (img === 'message') {
                return (
                  <div className="list-card" key={idx}>
                    <div className="card-title-wrapper">
                      <div className="card-img">
                        <img src={`/assets/community/${img}.svg`} alt={img} />
                      </div>
                      <div className="card-sub-wrapper">
                        {launage === 'KR' ? (
                          <div className="card-title">{title}</div>
                        ) : undefined}
                        <div className={`card-content `}>
                          {launage === 'KR' ? (
                            <div className="card-sub">{sub}</div>
                          ) : (
                            <div
                              className="card-title"
                              style={{ lineHeight: 1.39 }}
                            >
                              Contact <br />
                              {engtitle}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card-nav-wrapper">
                      <a
                        className="card-nav-btn gmarket"
                        target="__blank"
                        href="https://t.me/reechew"
                      >
                        <div className="card-nav-title">Contact CEO</div>
                        <div className="card-nav-arrow">
                          <img
                            src={`/assets/community/card-${
                              agent === 'desktop' ? '' : 'short'
                            }arrow.svg`}
                            alt=""
                          />
                        </div>
                      </a>
                      <a
                        className="card-nav-btn gmarket"
                        target="__blank"
                        href="https://t.me/minted_admin"
                      >
                        <div className="card-nav-title">Ad Inquiries</div>
                        <div className="card-nav-arrow">
                          <img
                            src={`/assets/community/card-${
                              agent === 'desktop' ? '' : 'short'
                            }arrow.svg`}
                            alt=""
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="list-card" key={idx}>
                    <div className="card-title-wrapper">
                      <div className="card-img">
                        <img src={`/assets/community/${img}.svg`} alt={img} />
                      </div>
                      <div className="card-sub-wrapper">
                        {launage === 'KR' ? (
                          <div className="card-title">{title}</div>
                        ) : undefined}
                        <div className="card-content">
                          {launage === 'KR' ? (
                            <div className="card-sub">{sub}</div>
                          ) : (
                            <div
                              className="card-title"
                              style={{ lineHeight: 1.39 }}
                            >
                              Contact <br />
                              {engtitle}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card-nav-wrapper" onClick={__navMail}>
                      <div className="card-nav-btn gmarket">
                        <div className="card-nav-title">Send E-mail</div>
                        <div className="card-nav-arrow">
                          <img
                            src={`/assets/community/card-${
                              agent === 'desktop' ? '' : 'short'
                            }arrow.svg`}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Community;
