import React from 'react';
import { useSelector } from 'react-redux';

const basicSrc = '/assets/portfolio';

function Coin({ name, url, display }) {
  const agent = useSelector((state) => state.config.identification.agent);
  return (
    <li className="brand">
      <img src={`${basicSrc}/${name}.svg`} alt="coin" />
      <div className="go-page">
        <a className="bg" href={url} target="_blank" rel="noreferrer">
          {' '}
        </a>
        <a href={url} target="_blank" rel="noreferrer" className="content">
          <div className="title gmarket txt-bold">{display}</div>
          {agent !== 'mobile' && (
            <div className="go-homepage-btn">
              <div className="title gmarket">Go homepage</div>
              <div className="asset">
                <img src="/assets/portfolio/next.svg" alt="next" />
              </div>
            </div>
          )}
        </a>
      </div>
    </li>
  );
}

export default Coin;
