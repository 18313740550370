import React from 'react';
import useScrollFadeIn from '../hook/useScrollFadeIn';
function ContactBox({
  data: { img, title, sub, tag, tb, engtitle, engsub, engtb, delay, mbdelay },
  language,
  agent,
  idx,
}) {
  return (
    <div
      className="section2-box"
      {...useScrollFadeIn(agent === 'mobile' ? mbdelay : delay)}
    >
      {idx !== 0 && <div className="section2-bar" />}
      <div className="box-wrapper">
        <div className="box-img">
          <img src={`/assets/about/${img}.svg`} alt="icon" />
        </div>
        <div className="box-right">
          <div className="box-tag txt-bold gmarket">{tag}</div>
          <div
            className="box-title txt-bold"
            style={
              language === 'ENG'
                ? {
                    whiteSpace: 'pre-line',
                    marginBottom: '23.4px',
                    marginTop: idx === 0 ? '14.2px' : '5px',
                    lineHeight: idx === 1 ? 1.25 : undefined,
                  }
                : undefined
            }
          >
            {language === 'KR' ? title : engtitle}
          </div>
          <div
            className={`box-sub ${tag}`}
            style={
              language === 'ENG'
                ? {
                    width:
                      agent === 'tablet'
                        ? '295px'
                        : agent === 'desktop'
                        ? '363px'
                        : undefined,
                  }
                : undefined
            }
          >
            {idx === 2 && (
              <span className="txt-bold">
                {language === 'KR' ? tb : engtb} <br />
              </span>
            )}
            {language === 'KR' ? sub : engsub}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactBox;
