const initialState = {
  identification: {
    version: '1.0.0',
    language: 'ENG',
    agent: 'desktop',
    productionPath: 'production',
  },
};
const config = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@config/CHANGE_LANGUANGE':
      return {
        ...state,
        identification: {
          ...state.identification,
          language: payload,
        },
      };
    case '@config/CHANGE_AGENT':
      return {
        ...state,
        identification: {
          ...state.identification,
          agent: payload,
        },
      };
    default:
      return state;
  }
};
export default config;
