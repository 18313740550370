import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './Home/Home';
import Header from './Header/Header';
import './css/core.css';
import Menu from './Menu/Menu';
import About from './About/About';
import Portfolio from './Portfolio/Portfolio';
import Blog from './Blog/Blog';
import Community from './Community/Community';
import Footer from './Footer/Footer';
import Email from './Email/Email';
function App() {
  return (
    <Router>
      <Header />
      <Menu />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/portfolio" exact component={Portfolio} />
        <Route path="/blog" exact component={Blog} />
        <Route path="/community" exact component={Community} />
        <Route path="/email" exact component={Email} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
